/* Optimized App.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root, .app-container {
  height: 100%;
  width: 100%;
}

html, body, #root {
  overflow: hidden;
}

.app-container {
  display: flex;
  flex-direction: column;
}

.header, .footer {
  flex: 0 0 50px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.header {
  justify-content: flex-end;
}

.footer {
  justify-content: center;
}

.auth-button-container, .button-container {
  display: flex;
  align-items: center;
}

.auth-button, .footer-button {
  margin: 0 5px;
  padding: 10px 20px;
  font-size: 16px;
}

.content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}